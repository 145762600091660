<template>
  <div class="about">
    <Navbar/>  
    <div class="container main-div">
        <div class="row">
          
          <div class="col-12 col-lg-6 offset-lg-3">
            <div class="h1-wrapper">
                <h1>Контакты</h1>
            </div>
            <div>
                <p>Москва - Париж</p>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <div>
                        <p>
                        Instagram
                        </p>
                    </div>
                    <div class="cta-wrapper">
                        <a class="btn btn-light btn-insta" href="https://instagram.com/tani.paris">
                            <img class="user-icon" src='@/assets/instagram-white.svg' alt=""> Наш Instagram - 21k 
                        </a>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div>
                        <p>
                        Telegram
                        </p>
                    </div>
                    <div class="cta-wrapper">
                        <a class="btn btn-light" href="https://t.me/tani_paris" style="background-color:darkblue; color:white; font-weight:bold">
                            TELEGRAM
                        </a>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'

export default {
  name: 'BusinessSchools',
  components: {
    Navbar
  }
}
</script>
